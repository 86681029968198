import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Avatar from 'components/common/Avatar';
import { cowryIcon, gameCoin } from 'assets';
import { Button } from 'components/button/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	_getUser,
	_isAnEmptyObject,
	storeReferralUsername,
	// formatMoney,
	currencyFormatter,
} from 'utils';
import { getUserCrown } from 'utils/userCrown';
import { userActions } from 'store/slices/user/userSlice';
import { getUserProfile } from 'api/profileApiService';
import { referralSystemTotal } from 'api/accountApiRequest';
import { GameCoinModal } from 'components/modals/account/GameCoin';
import { TelegramBotModal } from 'components/modals/TelegramBotModal';

interface Props {
	theme: string;
	toggleTheme: (e: string) => void;
	className?: string;
}

export interface ModalProp {
	status: boolean;
	type: string;
	modalObj: any;
}

export const Navbar = ({ theme, toggleTheme, className }: Props) => {
	const user = _getUser();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [params] = useSearchParams();

	const [, setOpen] = useState(false);
	const [, setAuthRefView] = useState('');

	const { userSubscription } = useSelector((state: any) => state.userReducer);

	const [modal, setModal] = useState<ModalProp>({
		status: false,
		type: '',
		modalObj: {},
	});

	const openModal = (type: string, modalObj?: any) =>
		setModal({ status: true, type, modalObj });
	const closeModal = () => setModal({ status: false, type: '', modalObj: {} });

	useEffect(() => {
		const refName = params.get('ref');
		if (refName) {
			// TODO: should this work when the user is already logged in?
			storeReferralUsername(refName);
			setOpen(true);
			setAuthRefView('register');
		}
	}, [params]);

	const { data: userProfileData } = useQuery(
		['user-profile'],
		async () => await getUserProfile(),
		{
			onSuccess: (res: any) => {
				console.log('userCurrency', res?.data?.localCurrency?.local?.symbol);
				dispatch(userActions.addUserProfile(res?.data));
				localStorage.setItem(
					'userCurrency',
					res?.data?.localCurrency?.local?.symbol
				);
			},
			// retry: 0,
			enabled: !_isAnEmptyObject(user),
		}
	);

	useEffect(() => {
		if (user?.userName) {
			referralSystemTotal().then((d) => {
				dispatch(userActions.addReferralData(d.data));
			});
		}
	}, [user?.userName, dispatch]);

	return (
		<nav
			className={`${
				location.pathname.includes('play') || location.pathname === '/'
					? 'bg-[#000000CC]'
					: 'bg-[#FFFFFF0A]'
			} border border-solid border-[#FFFFFF14]`}
		>
			<div
				className={`${className} relative w-full px-[3%] box-border flex items-center justify-between py-[0.5rem] overflow-x-auto`}
			>
				{user !== null ? (
					<div className="relative">
						<div
							onClick={() => navigate('/account')}
							className="profile flex items-center gap-2 cursor-pointer relative"
						>
							<>
								{userProfileData?.data?.myProfile?.imageUrl ? (
									<img
										style={{ width: '40px', height: '40px' }}
										src={userProfileData?.data?.myProfile?.imageUrl}
										className="rounded-full border-solid border-2 border-white"
										alt="avatar"
									/>
								) : (
									<Avatar
										text={user?.userName}
										size="w-[2.5rem] h-[2.5rem] rounded-full border-solid border-2 border-white"
									/>
								)}
							</>
							<div>
								<p className="text-white text-[15px] font-semibold">
									@{user?.userName && user?.userName}
								</p>
								<>{getUserCrown(userSubscription)}</>
							</div>
						</div>
					</div>
				) : (
					<div
						className="profile flex items-center gap-1 cursor-pointer"
						onClick={() =>
							_isAnEmptyObject(user) && navigate('/auth/get-started')
						}
					>
						<Avatar text={''} size="w-[2rem] h-[2rem]" />
					</div>
				)}

				<div className="flex relative">
					<button
						className={`hidden underline text-[10px] mr-4 ${
							theme === 'dark' ? 'text-white' : 'text-black'
						}`}
						onClick={() =>
							theme === 'dark' ? toggleTheme('light') : toggleTheme('dark')
						}
					>
						Switch Mode
					</button>

					{!_isAnEmptyObject(user) && (
						<div className="flex items-center gap-3">
							<div className="flex items-center gap-1 md:gap-4 bg-[#FFFFFF1F] h-[2rem] pr-1 md:h-[3.12rem] md:px-4 border border-[#FFFFFF42] rounded-[15px]">
								<Button
									text={
										userProfileData?.data?.freeModeBalance?.mainBalance > 0
											? currencyFormatter(
													userProfileData?.data?.freeModeBalance?.mainBalance
												)
											: '0.00'
									}
									icon={gameCoin}
									onClick={() => openModal('game-coin')}
									className="util-btn h-[1.5rem] md:h-[2.3rem] text-[14px] md:text-[14px] font-medium text-white flex items-center justify-center px-2 rounded-[7px] md:rounded-[15px] border-[#FFFFFF42]"
									iconClass="w-[1.25rem]"
								/>
							</div>
							<div className="flex items-center gap-1 md:gap-4 bg-[#FFFFFF1F] h-[2rem] pr-1 md:h-[3.12rem] md:px-4 border border-[#FFFFFF42] rounded-[15px]">
								<Button
									text={
										userProfileData?.data?.gameCurrencyBalance?.balance
											?.mainBalance > 0
											? currencyFormatter(
													userProfileData?.data?.gameCurrencyBalance?.balance
														?.mainBalance
												)
											: '0.00'
									}
									icon={cowryIcon}
									onClick={() => navigate('/account/withdraw/history')}
									className="util-btn h-[1.5rem] md:h-[2.3rem] text-[14px] md:text-[14px] font-medium text-white flex items-center justify-center px-2 rounded-[7px] md:rounded-[15px] border-[#FFFFFF42]"
									iconClass="w-[1.25rem]"
								/>
							</div>
						</div>
					)}
				</div>
			</div>

			{modal.status && modal.type === 'game-coin' && (
				<TelegramBotModal
					isOpen={modal.status}
					modalClose={closeModal}
					closeIcon={false}
					modalChild={
						<GameCoinModal
							modalClose={closeModal}
							gamecoin={
								userProfileData?.data?.freeModeBalance?.mainBalance > 0
									? currencyFormatter(
											userProfileData?.data?.freeModeBalance?.mainBalance
										)
									: '0.00'
							}
						/>
					}
				/>
			)}
		</nav>
	);
};
